import axios from 'axios'

export const getRandomName = () => (
  axios
    .get(`https://us-central1-triviawang.cloudfunctions.net/getRandomName`)
    .then(({ data }) => data)
)

// import { functions } from 'services/firebase'

// export const getRandomName = () => (
//   functions.httpsCallable('getRandomName')()
// )
