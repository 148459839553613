import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider'
import { auth } from 'services/firebase'

import './style.css'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  }
}))


const UserLayout = ({ children }) => {
  const classes = useStyles()
  const [menuOpen, toggleMenu] = useState(false)

  const user = useSelector(state => state.user.auth)


  const onMenuToggle = () => {
    toggleMenu(!menuOpen)
  }

  return (
    <>
      <AppBar position="static">
      <Toolbar>
        <IconButton onClick={onMenuToggle} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>

        <Typography variant="h6" className={classes.title}>
          TRIVA WANG
        </Typography>

        {auth && (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={onMenuToggle}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>

          </div>
        )}
      </Toolbar>
    </AppBar>
    {children}


    <Drawer open={menuOpen} onClose={onMenuToggle} onClick={onMenuToggle}>
      <div className="main-nav">
        <List>
            <ListItem button>
              <ListItemAvatar>
                <Avatar alt={user.displayName} src={user.photoURL} className={classes.avatar}>
                  {!user.photoURL && <AccountCircle />}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={user.displayName} secondary="Go to my profile" />
            </ListItem>

            <ListItem button>
              <ListItemText primary={'Start new game'} />
            </ListItem>

            <ListItem button>
              <ListItemText primary={'Go to existing game'} />
            </ListItem>

            <Divider />

            <ListItem button onClick={() => auth.signOut().then(console.log)}>
              <ListItemText primary={'Log out'} />
            </ListItem>
        </List>
      </div>
    </Drawer>

  </>
  )
}

export default UserLayout
