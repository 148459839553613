import React, { useState } from "react"
import { Link as RouterLink } from "@reach/router"
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import FingerprintIcon from '@material-ui/icons/Fingerprint'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Link from "@material-ui/core/Link"
import { auth } from "services/firebase"
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  google: {
    margin: theme.spacing(3, 0, 2),
  },
  divider: {
    margin: theme.spacing(3, 0, 3)
  }
}))

const SignIn = () => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)

  const submitRegistration = async (event) => {
    event.preventDefault()

    if(!email || !password) return

    try {
      const { user } = await auth.createUserWithEmailAndPassword(email, password)
      await user.updateProfile({
        displayName
      })
    } catch (err) {
      setError(err.message)
    }
  }

  const onEmailChange = event => {
    setError(null)
    setEmail(event.currentTarget.value)
  }

  const onPasswordChange = event => {
    setError(null)
    setPassword(event.currentTarget.value)
  }

  const onDisplayNameÇhange = event => {
    setError(null)
    setDisplayName(event.currentTarget.value)
  }

  return (
    <>
      <Avatar className={classes.avatar}>
        <FingerprintIcon />
      </Avatar>
      <Typography component="h1" variant="h4">
        Register for Trivia Wang
      </Typography>

      <Divider className={classes.divider} />
      {error && <p>{error}</p>}

      <form className={classes.form} onSubmit={submitRegistration}>

      <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Display Name"
          name="display name"
          autoComplete="name"
          onChange={onDisplayNameÇhange}
          autoFocus
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          onChange={onEmailChange}
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          onChange={onPasswordChange}
          autoComplete="current-password"
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!email.length || !password.length}
          className={classes.google}
        >
          Create Account
        </Button>

        <Grid container>
          <Grid item>
            <Link component={RouterLink} to="/auth/sign-in" variant="body2">

              <Grid container direction="row">
                <Grid item>
                  <KeyboardBackspaceIcon />
                </Grid>
                <Grid item className={classes.backToLogin}>
                  Back to login
                </Grid>
              </Grid>
            </Link>
          </Grid>
        </Grid>
      </form>
  </>
  )
}

export default SignIn


