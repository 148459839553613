import React from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import './style.css'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: props => ({
    backgroundImage: props.image,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  })
}))

const SideBarLayout = ({
  children,
  image = 'url(https://images.unsplash.com/photo-1556316918-b8b177b155cc?ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80)'
}) => {
  const classes = useStyles({ image })

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <div className={`${classes.paper} sidebar-layout-content`}>
       {children}
      </div>
      </Grid>
    </Grid>
  )
}

export default SideBarLayout
