import React, { useState } from "react"
import { Link as RouterLink } from "@reach/router"
import { signInWithGoogle } from 'services/auth/google'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import FingerprintIcon from '@material-ui/icons/Fingerprint'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import SvgIcon from "@material-ui/core/SvgIcon"
import Link from "@material-ui/core/Link"
import { auth } from "services/firebase"

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  google: {
    margin: theme.spacing(3, 0, 2),
  },
  divider: {
    margin: theme.spacing(3, 0, 3)
  }
}))

const SignIn = () => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)

  const submitEmailLogin = async (event) => {
    event.preventDefault()

    if(!email || !password) return

    try {
      await auth.signInWithEmailAndPassword(email, password)
    } catch (err) {
      setError(err.message)
    }
  }

  const onEmailChange = event => {
    setError(null)
    setEmail(event.currentTarget.value)
  }

  const onPasswordChange = event => {
    setError(null)
    setPassword(event.currentTarget.value)
  }

  return (
    <>
      <Avatar className={classes.avatar}>
        <FingerprintIcon />
      </Avatar>
      <Typography component="h1" variant="h4">
        Login to Trivia Wang
      </Typography>

      <Divider className={classes.divider} />

      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={signInWithGoogle}
        className={classes.google}
        startIcon={
          <SvgIcon>
            <path d="M21,12.2177419 C21,13.9112905 20.6311475,15.4233869 19.8934426,16.7540323 C19.1557377,18.0846776 18.1168031,19.1249998 16.7766393,19.875 C15.4364756,20.6250002 13.8934424,21 12.147541,21 C10.4999998,21 8.97540984,20.5947579 7.57377049,19.7842742 C6.17213115,18.9737905 5.05942604,17.8790323 4.23565574,16.5 C3.41188543,15.1209677 3,13.6209679 3,12 C3,10.3790321 3.41188543,8.87903226 4.23565574,7.5 C5.05942604,6.12096774 6.17213115,5.02620949 7.57377049,4.21572581 C8.97540984,3.40524212 10.4999998,3 12.147541,3 C14.5327871,3 16.5737705,3.78629051 18.2704918,5.35887097 L15.7991803,7.71774194 C15.0122953,6.96774175 14.0655738,6.52016129 12.9590164,6.375 C11.9262295,6.22983871 10.9057375,6.375 9.89754098,6.81048387 C8.88934445,7.24596774 8.07786904,7.89919355 7.46311475,8.77016129 C6.79918033,9.71370968 6.46721311,10.7903228 6.46721311,12 C6.46721311,13.0403228 6.72540984,13.9899192 7.24180328,14.8487903 C7.75819672,15.7076615 8.4467215,16.3971776 9.30737705,16.9173387 C10.1680326,17.4374998 11.1147541,17.6975806 12.147541,17.6975806 C13.2540984,17.6975806 14.2254096,17.455645 15.0614754,16.9717742 C15.7254098,16.5846772 16.2786885,16.0645161 16.7213115,15.4112903 C17.0409838,14.8790321 17.2499998,14.3467744 17.3483607,13.8145161 L12.147541,13.8145161 L12.147541,10.6935484 L20.852459,10.6935484 C20.9508199,11.2258066 21,11.7338712 21,12.2177419 Z" />
          </SvgIcon>
        }
      >
          LOGIN WITH GOOGLE
      </Button>

      <Divider className={classes.divider} />
      {error && <p>{error}</p>}

      <form className={classes.form} onSubmit={submitEmailLogin}>

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          onChange={onEmailChange}
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          onChange={onPasswordChange}
          autoComplete="current-password"
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!email.length || !password.length}
          className={classes.google}
        >
          Sign In
        </Button>

        <Grid container>
          <Grid item xs>
            <Link component={RouterLink} to="/auth/reset-password" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link component={RouterLink} to="/auth/sign-up" variant="body2">
              Don't have an account? Sign Up
            </Link>
          </Grid>
        </Grid>
      </form>
  </>
  )
}

export default SignIn


