import produce from 'immer'
import config from 'config'

const resolveLocalAction = type => type.replace(`${config.REDUX_ACTION_NAMESPACE}_`, '')

function createReducer(initialState, actionsMap) {
  return function (state = initialState, action) {
    return produce(state, draft => {
      if (!action.type) {
        return draft
      }

      const caseReducer = actionsMap[resolveLocalAction(action.type)]

      if (caseReducer) {
        return caseReducer({ draft, action })
      }

      return draft
    })
  }
}

export default createReducer