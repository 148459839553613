import React from "react"
import { useSelector } from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'

import AccountCircle from '@material-ui/icons/AccountCircle'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import { auth } from 'services/firebase'

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    height: 100,
    width: 100
  },
  divider: {
    margin: theme.spacing(3, 0, 3)
  },
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  }
}))

const ProfilePage = () => {
  const classes = useStyles()
  const user = useSelector(state => state.user.profile)

  return (
    <>
      <div className={classes.root} >
        <Avatar alt={user.displayName} src={user.photoURL} className={classes.avatar} variant="rounded">
            {!user.photoURL && <AccountCircle />}
        </Avatar>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5">
            {user.displayName}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
            @{user.handle}
            </Typography>
          </CardContent>
        </div>
      </div>



      <Divider className={classes.divider} />

      <Typography component="h1" variant="h5">
        Welcome to the danger zone, {user.displayName}
      </Typography>

      <Divider className={classes.divider} />


      <Button onClick={() => auth.signOut().then(console.log)}>SIGN OUT</Button>

  </>
  )
}

export default ProfilePage;


