export const stripSpecialChars = (str) => (
  str.replace(/[^a-zA-Z0-9_.]/, '')
)

export const camel = str => (
    str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (
        index === 0
          ? word.toLowerCase()
          : word.toUpperCase()
      ))
      .replace(/\s+/g, '')
)

export const nameToHandle = str => (
    stripSpecialChars(
      camel(str)
    )
)
