import { combineReducers } from 'redux'

import config from 'config'
import createReducer from './createReducer'

const { REDUX_ACTION_NAMESPACE } = config

const actionType = action => `${REDUX_ACTION_NAMESPACE}_${action}`

const createAction = ({ type, ...rest }) => ({
  type: actionType(type),
  ...rest
})

const mergeReducers = combineReducers

export {
  createReducer,
  createAction,
  actionType,
  mergeReducers,
  REDUX_ACTION_NAMESPACE
}
