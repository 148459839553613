import React from "react"
import { Router, Redirect } from "@reach/router"
import { useSelector } from 'react-redux'
import SignIn from "components/SignIn"
import SignUp from "components/SignUp"
import ProfilePage from "components/ProfilePage"
import PasswordReset from "components/PasswordReset"
import SideBarLayout from 'layouts/SideBar'
import UserLayout from 'layouts/User'
import './style.css'

function AppLayout() {
  const userAuth = useSelector(state => state.user.auth)

  return (
    <Router className="default-router-wrapper">
      {userAuth ? (
        <>
        <UserLayout path="/access/*" default>
          <SideBarLayout path="/profile" default>
            <ProfilePage path="/" default />
          </SideBarLayout>
        </UserLayout>

        <Redirect from="/auth/*" to="/access/profile" noThrow />
        <Redirect from="/" to="/access/profile" noThrow />
        </>
       ) : (
         <>
          <SideBarLayout path="/auth">
            <SignUp path="/sign-up" />
            <PasswordReset path="/reset-password" />
            <SignIn path="/" default />
          </SideBarLayout>

          <Redirect from="/access/*" to="/auth/" noThrow />
        </>
      )}
      <Redirect from="/" to="/auth/" noThrow />
    </Router>
  )
}
export default AppLayout
