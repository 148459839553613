import React, { useState } from "react"
import { Link as RouterLink } from "@reach/router"
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FingerprintIcon from '@material-ui/icons/Fingerprint'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import Link from "@material-ui/core/Link"
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { auth } from "services/firebase"
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    minWidth: '100%'
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2),
  },
  divider: {
    margin: theme.spacing(3, 0, 3)
  },
  backToLogin: {
    margin: `2px 0 0 0`
  }
}))

const PasswordReset = () => {
  const classes = useStyles()
  const [email, setEmail] = useState("")
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false)
  const [error, setError] = useState(null)

  const onChangeEmail = event => {
    const { value } = event.currentTarget
    setError(null)
    setEmail(value)
  }

  const submitPasswordReset = async (event) => {
    event.preventDefault()
    if(!email.length) return

    try {
      await auth.sendPasswordResetEmail(email)
      setEmailHasBeenSent(true)
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <>
      <Avatar className={classes.avatar}>
        <FingerprintIcon />
      </Avatar>
      <Typography component="h1" variant="h4">
        Forgot your password, huh?
      </Typography>

      <p>
        Did you try p-a-s-s-w-o-r-d? (JK! dont do that) Enter the email you used to sign up with and we'll send you a link to reset it.
      </p>

      <Divider className={classes.divider} />
      {error && <FormHelperText error variant="outlined">{error}</FormHelperText>}
      {emailHasBeenSent && <FormHelperText variant="outlined"	>We just sent you an email to reset your password.</FormHelperText>}

      <form className={classes.form} onSubmit={submitPasswordReset} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          type="email"
          autoFocus
          onChange={onChangeEmail}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submitButton}
          disabled={!email.length}
        >
          Send Reset
        </Button>

        <Grid container>
          <Grid item>

            <Link component={RouterLink} to="/auth/sign-in" variant="body2">

              <Grid container direction="row">
                <Grid item>
                  <KeyboardBackspaceIcon />
                </Grid>
                <Grid item className={classes.backToLogin}>
                  Back to login
                </Grid>
              </Grid>
            </Link>
          </Grid>
        </Grid>
      </form>
    </>
  )
}
export default PasswordReset
