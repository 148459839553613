import { createStore } from 'redux'
import { createReducer } from 'utils/redux'
import { rootReducer } from 'modules'

export const configureStore = (initialState={}) => {
  const store = createStore(
  	rootReducer,
  	initialState,
  	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
  store.asyncReducers = {}

  return store
}

export const injectAsyncReducer = (store, asyncReducers) => {
  store.asyncReducers = asyncReducers
  store.replaceReducer(createReducer(store.asyncReducers))
}

export default configureStore()
