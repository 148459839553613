import { firestore } from 'services/firebase'
import { identity, memoizeWith } from 'ramda'
import { nameToHandle } from 'utils'
import { getRandomName } from 'api/names'

export const getUserProfile = memoizeWith(identity, async (userId) => {
  try {
    const user = await firestore
      .doc(`users/${userId}`)
      .get()

      return user.exists ? user.data() : null
  } catch (err) {

    return null
  }
})

export const createUserProfile = memoizeWith(identity, async (userData) => {
  try {
    const user = await getUserProfile(userData.uid)

    if(!user) {
      const handle = userData.displayName && !userData.displayName.includes('@')
        ? nameToHandle(userData.displayName)
        : await getRandomName()

      const profileData = {
        ...userData,
        handle
      }

      await firestore
        .doc(`users/${userData.uid}`)
        .set(profileData)

      return profileData
    }

    return user
  } catch (err) {
    console.error(err)
  }
})
