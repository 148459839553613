import { createReducer } from 'utils/redux'

const defaultState = {
  auth: false,
  profile: {}
}

const receiveUserHandler = ({ draft, action }) => {
  draft.auth = action.payload.auth
  draft.profile = action.payload.user
}

const actions = {
  RECEIVE_USER: receiveUserHandler
}

const userReducer = createReducer(
  defaultState,
  actions
)

export default userReducer
