// @flow

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import store from "src/store"
import { pick } from 'ramda'
import { receiveUser } from 'modules/user/actions'
import { createUserProfile } from 'services/user'

const firebaseConfig = {
  apiKey: "AIzaSyCkVMYVxPi9MMWoyobvIvmZ5ol7ZLTEP4g",
  authDomain: "triviawang.firebaseapp.com",
  databaseURL: "https://triviawang.firebaseio.com",
  projectId: "triviawang",
  storageBucket: "triviawang.appspot.com",
  messagingSenderId: "73132480421",
  appId: "1:73132480421:web:53c88b0cd625edef517a4c",
  measurementId: "G-DLFWCHWP21"
}

firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
export const firestore = firebase.firestore()
export const functions = firebase.functions()

//listeners - move to files prob
auth.onAuthStateChanged(async (userAuth) => {
  if(!userAuth) {
   return store.dispatch(receiveUser({ auth: false, user: null }))
  }


  const userDataForProfile = pick(['displayName', 'email', 'uid', 'photoURL'], userAuth)
  const user = await createUserProfile(userDataForProfile)

  store.dispatch(receiveUser({ auth: true, user }))
});
